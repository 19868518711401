/********************************************************************************************
 *   COPYRIGHT (C) 2024 RIoT
 *   The reproduction, transmission or use of this document/file or its
 *   contents is not permitted without  written authorization.
 *   Offenders will be liable for damages. All rights reserved.
 *---------------------------------------------------------------------------
 *   Purpose:  TablePagination .js
 *   Project:  RIoT
 *   IDE:	  VS Code
 ********************************************************************************************/
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
/**
 * TablePagination component for managing pagination controls and display.
 *
 * This component renders pagination buttons, page information, and controls for
 * navigating through pages and adjusting page size.
 *
 * @param {Object} props - Props passed to the component.
 * @param {Object} props.tableInstance - Instance of the react-table managing table state and rendering.
 * @param {Function} props.setPageIndex - Function to set the current page index.
 * @param {Function} props.setPageSize - Function to set the number of items per page.
 * @param {number} props.pageIndex - Current page index.
 * @param {number} props.pageSize - Number of items per page.
 * @param {number} props.totalRows - Total number of rows in the table.
 * @returns {JSX.Element} - Rendered JSX for the TablePagination component.
 */
const TablePagination = ({
  tableInstance,
  setPageIndex,
  setPageSize,
  pageIndex,
  pageSize,
  totalRows,
}) => {
  return (
    <div className="flex items-center justify-end gap-2 mt-4">
      {/* Button to go to the first page */}
      <button
        className="border rounded px-2 text-lg cursor-pointer bg-[#08605f] text-white hover:bg-[#45bcba] disabled:bg-gray-300 disabled:cursor-not-allowed"
        onClick={() => setPageIndex(0)}
        disabled={!tableInstance.getCanPreviousPage()}
      >
        <FontAwesomeIcon icon={faAngleDoubleLeft} />
      </button>

      {/* Button to go to the previous page */}
      <button
        className="border rounded px-2 text-lg cursor-pointer bg-[#08605f] text-white hover:bg-[#45bcba] disabled:bg-gray-300 disabled:cursor-not-allowed"
        onClick={() => setPageIndex((prev) => Math.max(prev - 1, 0))}
        disabled={!tableInstance.getCanPreviousPage()}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </button>

      {/* Display current page and total pages */}
      <span className="flex items-center gap-1">
        <div>
          <span className="text-sm">
            Page {pageIndex + 1} of {Math.ceil(totalRows / pageSize)}
          </span>
        </div>
      </span>

      {/* Button to go to the next page */}
      <button
        className="border rounded px-2 text-lg cursor-pointer bg-[#08605f] text-white hover:bg-[#45bcba] disabled:bg-gray-300 disabled:cursor-not-allowed"
        onClick={() =>
          setPageIndex((prev) =>
            Math.min(prev + 1, Math.ceil(totalRows / pageSize) - 1)
          )
        }
        disabled={!tableInstance.getCanNextPage()}
      >
        <FontAwesomeIcon icon={faAngleRight} />
      </button>

      {/* Button to go to the last page */}
      <button
        className="px-2 text-lg cursor-pointer bg-[#08605f] text-white hover:bg-[#45bcba] disabled:bg-gray-300 disabled:cursor-not-allowed"
        onClick={() => setPageIndex(Math.ceil(totalRows / pageSize) - 1)}
        disabled={pageIndex >= Math.ceil(totalRows / pageSize) - 1}
      >
        <FontAwesomeIcon icon={faAngleDoubleRight} />
      </button>

      {/* Input field to enter a specific page number */}
      <span className="flex items-center gap-1">
        | Go to page:
        <input
          type="number"
          defaultValue={tableInstance.getState().pagination.pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 1;

            setPageIndex(page);
          }}
          className="border p-1 border-[#08605F] rounded w-16"
        />
      </span>

      {/* Dropdown to select the number of items per page */}
      <select
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
          setPageIndex(0);
        }}
        className="border p-1 border-[#08605F] rounded cursor-pointer bg-white hover:bg-gray-200"
      >
        {[1, 2, 3, 4, 5, 10].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TablePagination;
