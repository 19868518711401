import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="min-h-full flex flex-col items-center justify-center text-[#08605F]">
      <div className="text-center">
        <h1 className="text-5xl font-bold mb-6 animate-bounce">
          Welcome to RIoT
        </h1>
        <p className="text-lg mb-8 animate-slide-in">
          Revolutionizing the way you monitor and manage your resources.
        </p>
        <div className="flex justify-center space-x-4">
          <Link
            to="/batteryState"
            className="bg-white text-[#08605F] font-bold py-2 px-4 rounded shadow-md hover:bg-gray-200 transition duration-300 transform hover:scale-105"
          >
            Battery State
          </Link>
          <Link
            to="/alarmState"
            className="bg-white text-[#08605F] font-bold py-2 px-4 rounded shadow-md hover:bg-gray-200 transition duration-300 transform hover:scale-105"
          >
            Alarm State
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
