import { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove, faSpinner, faSave } from "@fortawesome/free-solid-svg-icons";

const Mqtt = () => {
  const [mqttValue, setMqttValue] = useState(""); // Default value for MQTT field
  const [loading, setLoading] = useState(false); // State to manage loading spinner
  const [successMessage, setSuccessMessage] = useState(""); // State to manage success message
  const [errorMessage, setErrorMessage] = useState(""); // State to manage error message

  const handleChange = (e) => {
    setMqttValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage(""); // Clear previous success messages
    setErrorMessage(""); // Clear previous error messages

    try {
      const response = await axios.post(
        "https://hnz9bdk37g.execute-api.us-west-2.amazonaws.com/prod/eDudeRIoTMQTTPublish",
        { message: mqttValue }, // Payload for the API
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSuccessMessage("Value updated successfully!");
        setTimeout(() => {
          setSuccessMessage(""); // Clear success message after 2 minutes
          setMqttValue(""); // Reset the form
        }, 20000); // 2 minutes = 120000 ms
      } else {
        throw new Error("Failed to update value");
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || error.message || "Something went wrong"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-80 z-30">
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            size="4x"
            className="text-white mb-4"
          />
          <div className="text-white text-xl">Loading...</div>
        </div>
      )}

      <div className="fixed inset-0 flex items-center justify-center">
        <div className="bg-[#cae7f5] px-12 py-6 rounded w-2/3 max-w-lg relative">
          <p
            id="formErrorMessage"
            className="text-red-500 text-center mb-4"
            hidden={!errorMessage}
          >
            {errorMessage}
          </p>
          <p
            id="formSuccessMessage"
            className="text-green-500 text-center mb-4"
            hidden={!successMessage}
          >
            {successMessage}
          </p>
          <form className="bg-white rounded-lg p-6" onSubmit={handleSubmit}>
            <div className="mt-2 flex justify-center flex-col items-center w-full py-4 px-3">
              <label className="text-[#08605F] font-semibold text-sm mb-2">
                MQTT Field:
              </label>
              <input
                type="text"
                value={mqttValue}
                name="mqttValue"
                onChange={handleChange}
                className="bg-gray-50 border-2 border-gray-600 rounded-md px-2 py-1 ml-4 w-full"
                placeholder="Enter MQTT value"
              />
            </div>

            <div className="flex justify-center mt-4">
              <button
                type="submit"
                className="px-8 py-2 bg-[#94E8B4] text-black mt-2 text-sm rounded-xl shadow-xl"
              >
                <FontAwesomeIcon
                  icon={faSave}
                  size="1x"
                  className="text-black mr-2"
                />
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Mqtt;
