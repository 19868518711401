/**
 * Selection column configuration for a table.
 *
 * This object defines a column in a table that includes checkboxes for row selection.
 * It provides both a header checkbox to select/deselect all rows and individual row checkboxes
 * to toggle the selection state of each row.
 *
 * @type {Object}
 * @property {string} id - Identifier for the selection column.
 * @property {Function} header - Component function for rendering the header cell.
 *    - Uses an input checkbox to toggle selection of all rows in the table.
 * @property {Function} cell - Component function for rendering each cell in the selection column.
 *    - Renders an input checkbox for each row to toggle its selection state.
 * @returns {Object} - Returns the selection column configuration object.
 */
const selectionColumn = {
  // Identifier for the selection column
  id: "selection",
  header: ({ table }) => (
    <input
      type="checkbox"
      checked={table.getIsAllRowsSelected()} // Checks if all rows are selected
      onChange={table.getToggleAllRowsSelectedHandler()} // Toggles selection for all rows
      className="accent-[#08605f]" // Custom styling for the checkbox
    />
  ),
  cell: ({ row }) => (
    <input
      type="checkbox"
      checked={row.getIsSelected()} // Checks if the current row is selected
      onChange={row.getToggleSelectedHandler()} // Toggles selection for the current row
      className="accent-[#08605f]" // Custom styling for the checkbox
    />
  ),
};

export default selectionColumn;
