import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery"; // Update this import

const AppLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [footerPosition, setFooterPosition] = useState("fixed");
  const isMobile = useMediaQuery("(max-width: 640px)"); // Assuming mobile view is for screens smaller than 640px

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setFooterPosition("relative");
      } else {
        setFooterPosition("fixed");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    // Close the sidebar when the view switches to mobile
    if (isMobile) {
      setIsSidebarOpen(false);
    }
  }, [isMobile]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <div className="flex flex-grow">
        <Navbar
          isOpen={isSidebarOpen}
          toggleNavbar={() => setIsSidebarOpen(!isSidebarOpen)}
          isMobile={isMobile}
        />

        <main
          className={`flex-grow pt-16 transition-all duration-300 ${
            isMobile ? "" : isSidebarOpen ? "ml-48" : "ml-16"
          }`}
        >
          <Outlet />
        </main>
      </div>

      <Footer isSidebarOpen={isSidebarOpen} footerPosition={footerPosition} />
    </div>
  );
};

export default AppLayout;
