/********************************************************************************************
 *   COPYRIGHT (C) 2024 RIoT
 *   The reproduction, transmission or use of this document/file or its
 *   contents is not permitted without  written authorization.
 *   Offenders will be liable for damages. All rights reserved.
 *---------------------------------------------------------------------------
 *   Purpose:  App.js
 *   Project:  RIoT
 *   IDE:	  VS Code
 ********************************************************************************************/
import React from "react";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AppLayout from "./components/AppLayout";
import BatteryState from "./components/BatteryState";
import Home from "./components/Home";
import AlarmState from "./components/AlarmState";
import Mqtt from "./components/Mqtt";

/**
 * appRouter Configuration
 *
 * Defines the routing configuration using createBrowserRouter from react-router-dom.
 * This configuration sets up routes for different paths in the application,
 * lazy-loading components and providing fallback UI during loading using Suspense.
 */
const appRouter = createBrowserRouter([
  {
    path: "/",
    // Main layout component for the entire application
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/batteryState",
        element: <BatteryState />,
      },

      {
        path: "/alarmState",
        element: <AlarmState />,
      },
      {
        path: "/mqtt",
        element: <Mqtt />,
      },
    ],
  },
]);

/**
 * App Component
 *
 * Main component that wraps the application with RouterProvider, providing
 * the appRouter configuration to manage routing within the application.
 */

const App = () => {
  return (
    <div>
      <RouterProvider router={appRouter} />
    </div>
  );
};

export default App;
