import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { UPDATE_ALARM_STATE } from "../utils/constants";

const UpdateAlarmState = ({ isOpen, onClose, formData, setData }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [localFormData, setLocalFormData] = useState({
    "eMail Alarm Message": "",
    "Text Alarm Message": "",
    "eMail Address": "",
    "Text Message Number": "",
    "Device Name": "",
    "eDude HWID": "",
  });

  useEffect(() => {
    if (isOpen) {
      setLocalFormData(formData);
    }
  }, [isOpen, formData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsUpdating(true);

    const data = {
      eDudeHWID: localFormData["eDude HWID"],
      eDudeName: localFormData["Device Name"],
      eMailAlarmMessage: localFormData["eMail Alarm Message"],
      textAlarmMessage: localFormData["Text Alarm Message"],
      eMailAddress: localFormData["eMail Address"],
      textMessageNumber: String(localFormData["Text Message Number"]),
    };

    try {
      const response = await fetch(UPDATE_ALARM_STATE, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Failed to update data: ${response.statusText}`);
      }
      console.log(data);
      let updatedData = localFormData;

      setData((prevData) =>
        prevData.map((obj) =>
          obj["eDude HWID"] === updatedData["eDude HWID"] ? updatedData : obj
        )
      );

      onClose();
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      {isUpdating && (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-80 z-30">
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            size="4x"
            className="text-white mb-4"
          />
          <div className="text-white text-xl">Loading...</div>
        </div>
      )}

      {!isUpdating && isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-20">
          <div className="bg-[#cae7f5] px-12 py-6 rounded shadow-lg w-2/3 max-w-lg relative">
            <button
              className="absolute top-0 right-0 px-2 py-1 hover:bg-red-600"
              onClick={onClose}
            >
              <FontAwesomeIcon icon={faRemove} className="text-black" />
            </button>

            <form onSubmit={handleSubmit} className="bg-white rounded-lg p-6">
              <input type="hidden" value={localFormData["eDude HWID"]} />
              <div className="mt-2 flex justify-center flex-col items-center w-full py-4 px-3 ">
                <label className="text-[#08605F] font-semibold text-sm mb-2">
                  eMail Alarm Message:
                </label>
                <input
                  type="text"
                  value={localFormData["eMail Alarm Message"]}
                  name="eMail Alarm Message"
                  onChange={handleChange}
                  className="bg-gray- border-2 border-gray-600 rounded-md px-2 py-1 ml-4 w-full"
                />
              </div>
              <div className="mt-2 flex justify-center flex-col items-center w-full py-4 px-3 ">
                <label className="text-[#08605F] font-semibold text-sm mb-2">
                  Text Alarm Message:
                </label>
                <input
                  type="text"
                  value={localFormData["Text Alarm Message"]}
                  name="Text Alarm Message"
                  onChange={handleChange}
                  className="bg-gray- border-2 border-gray-600 rounded-md px-2 py-1 ml-4 w-full"
                />
              </div>
              <div className="flex justify-center mt-4">
                <button
                  type="submit"
                  className="px-8 py-2 bg-[#94E8B4] text-black mt-2 rounded-xl shadow-xl"
                  disabled={isUpdating}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateAlarmState;
