import React from "react";
import Navbar from "./Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHamburger } from "@fortawesome/free-solid-svg-icons";

/**
 * Header component containing navigation and top bar icons.
 *
 * This component includes a navigation bar rendered by the Navbar component
 * and a fixed top bar with various icons for messaging, search, notifications,
 * and an additional icon. These icons provide quick access to different functionalities
 * or notifications within the application's header section.
 *
 * @returns {JSX.Element} - Rendered JSX header component.
 */
const Header = () => {
  return (
    <div>
      {/* Renders the Navbar component for navigation */}
      <Navbar />

      <div className="flex justify-between fixed top-0 border bg-gray-50 -z-0 border-b-1 border-b-[#000] p-1 w-full sm:w-fit md:w-fit lg:fit xl:w-full">
        <div>
          <FontAwesomeIcon icon={faHamburger} />
        </div>

        <div className="flex flex-row">
          {/** Message icon */}
          <img
            src="/message.png"
            alt="message"
            className="w-[24px] h-auto m-2"
          />
          {/** Search icon */}
          <img src="/search.png" alt="search" className="w-[24px] h-auto m-2" />
          {/** Notifications icon */}
          <img
            src="/notification.png"
            alt="notification"
            className="w-[24px] h-auto m-2"
          />
          {/** Additional icon */}
          <img
            src="/NoPath.png"
            alt="additional"
            className="w-[24px] h-auto m-2"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
