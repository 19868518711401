/********************************************************************************************
 *   COPYRIGHT (C) 2024 RIoT
 *   The reproduction, transmission or use of this document/file or its
 *   contents is not permitted without  written authorization.
 *   Offenders will be liable for damages. All rights reserved.
 *---------------------------------------------------------------------------
 *   Purpose:  UpdateForm.js
 *   Project:  RIoT
 *   IDE:	  VS Code
 ********************************************************************************************/
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { BATTERY_STATE_FORM_UPDATE_URL } from "../utils/constants";

/**
 * Component for displaying and updating battery state information.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.isOpen - Determines if the form is open.
 * @param {function} props.onClose - Callback to close the form.
 * @param {Object} props.formData - Form data to display and update.
 * @param {function} props.setFormData - Callback to update form data.
 * @param {function} props.setData - Callback to update external data after form submission.
 * @returns {JSX.Element} UpdateForm component.
 */
const UpdateForm = ({ isOpen, onClose, formData, setFormData, setData }) => {
  const [isUpdating, setIsUpdating] = useState(false);

  /**
   * Handles form submission.
   *
   * @param {Event} event - Submit event object.
   */
  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsUpdating(true);
    const data = {
      eDudeHWID: formData["eDude HWID"],
      eDudeName: formData["eDude Name"],
      lowVoltage: parseInt(formData["Low Voltage Alert Setting"]),
    };

    try {
      const response = await fetch(BATTERY_STATE_FORM_UPDATE_URL, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Failed to update data: " + response.statusText);
      }

      setData((prevData) =>
        prevData.map((obj) =>
          obj["eDude HWID"] === formData["eDude HWID"] ? formData : obj
        )
      );

      onClose();
    } catch (error) {
      console.error("Error updating data:", error.message);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return formData ? (
    <>
      {isUpdating && (
        // Display loading spinner while updating
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-80 z-30">
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            size="4x"
            className="text-white mb-4"
          />

          <div className="text-white text-xl">Loading...</div>
        </div>
      )}

      {!isUpdating && isOpen && (
        // Display update form when not updating and isOpen is true
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-20">
          <div className="bg-[#cae7f5] px-12 py-6 rounded shadow-lg w-2/3 max-w-lg relative">
            <button
              className="absolute top-0 right-0 px-2 py-1 hover:bg-red-600"
              onClick={onClose}
            >
              <FontAwesomeIcon icon={faRemove} className="text-black" />
            </button>

            {/* <h2 className="text-xl mb-4 text-white">Update Details</h2> */}

            <form onSubmit={handleSubmit} className="bg-white rounded-lg p-6">
              {/**  Hidden fields for additional form data */}
              {formData["eDude Name"] && (
                <input type="hidden" value={formData["eDude Name"]} />
              )}
              {formData["% Humidity"] && (
                <input type="hidden" value={formData["% Humidity"]} />
              )}
              {formData["Ambient Temperature"] && (
                <input type="hidden" value={formData["Ambient Temperature"]} />
              )}
              {formData["Batteries Installed On"] && (
                <input
                  type="hidden"
                  value={formData["Batteries Installed On"].split("T")[0]}
                />
              )}
              {formData["Battery Voltage"] && (
                <input
                  type="hidden"
                  value={formData["Battery Voltage"]}
                  name="Battery Voltage"
                />
              )}
              {formData["Location"] && (
                <input type="hidden" value={formData["Location"]} />
              )}
              {/**Displayed field and allow the user to edit this field value */}
              <div className="mt-2 flex justify-center flex-col items-center w-full  py-4 px-3 ">
                <label className="text-[#08605F] font-semibold text-sm mb-2">
                  Low Voltage Alarm Setting:
                </label>
                <input
                  type="number"
                  value={formData["Low Voltage Alert Setting"] || ""}
                  name="Low Voltage Alert Setting"
                  onChange={handleChange}
                  className="bg-gray- border-2 border-gray-600 rounded-md px-2 py-1 ml-4 w-full"
                  placeholder="Enter low voltage alarm setting"
                />
              </div>
              {/**This hidden input is used to store the eDude HWID */}
              <input type="hidden" value={formData["eDude HWID"]} />

              <div className="flex justify-center mt-4">
                {/**Update button is used to send response to API */}
                <button
                  type="submit"
                  className="px-8 py-2 bg-[#94E8B4] text-black mt-2 text-sm rounded-xl shadow-xl"
                  disabled={isUpdating}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  ) : null;
};

export default UpdateForm;
