/********************************************************************************************
 *   COPYRIGHT (C) 2024 RIoT
 *   The reproduction, transmission or use of this document/file or its
 *   contents is not permitted without  written authorization.
 *   Offenders will be liable for damages. All rights reserved.
 *---------------------------------------------------------------------------
 *   Purpose:  constant.js
 *   Project:  RIoT
 *   IDE:	  VS Code
 ********************************************************************************************/

// Column definitions for displaying battery state data in a table
export const columnDefinition = [
  {
    accessorKey: "eDude HWID",
    header: "eDude HWID",
    enableSorting: false,
  },
  {
    accessorKey: "eDude Name",
    header: "eDude Name",
  },
  {
    accessorKey: "Location",
    header: "Location",
    enableGlobalFilter: false,
  },
  {
    accessorKey: "Battery Voltage",
    header: "Battery Voltage",
    enableGlobalFilter: false,
  },
  {
    accessorKey: "Batteries Installed On",
    header: "Batteries Installed On",
    enableGlobalFilter: false,
  },
  {
    accessorKey: "Low Voltage Alert Setting",
    header: "Low Voltage Alarm Setting",
    enableGlobalFilter: false,
  },
  {
    accessorKey: "Ambient Temperature",
    header: "Ambient Temperature",
    enableGlobalFilter: false,
  },
  {
    accessorKey: "% Humidity",
    header: "% Humidity",
    enableGlobalFilter: false,
  },
];

// URL to fetch battery state data
export const GET_BATTTERY_STATE_DATA_URL =
  "https://qqkl1wsfha.execute-api.us-west-2.amazonaws.com/prod";

// URL to fetch current state data
export const CURRENT_STATE_FETCH_URL =
  "https://oyuqdil9tc.execute-api.us-west-2.amazonaws.com/prod";

// URL to update battery state form data
export const BATTERY_STATE_FORM_UPDATE_URL =
  "https://1rp0g24b48.execute-api.us-west-2.amazonaws.com/prod";

export const alarmSatusDataColumn = [
  {
    accessorKey: "eDude HWID",
    header: "eDude HWID",
    enableSorting: false,
  },
  {
    accessorKey: "Device Name",
    header: "Device Name",
    enableSorting: false,
  },
  {
    accessorKey: "eMail Address",
    header: "eMail Address",
    enableGlobalFilter: false,
  },
  {
    accessorKey: "eMail Alarm Message",
    header: "eMail AlarmMessage",
    enableGlobalFilter: false,
  },
  {
    accessorKey: "Text Message Number",
    header: "Text Message Number",
    enableGlobalFilter: false,
  },
  {
    accessorKey: "Text Alarm Message",
    header: "Text Alarm Message ",
    enableGlobalFilter: false,
  },
  {
    accessorKey: "Edit",
    header: "Edit",
    enableGlobalFilter: true,
  },
];

export const GET_ALARM_STATE_DATA =
  "https://yqkfydi9k7.execute-api.us-west-2.amazonaws.com/prod/eDudeRIoTNotification";

export const FETCH_DATA =
  "https://rekufmog80.execute-api.us-west-2.amazonaws.com/prod/eDudeRIoTGetUniqueNotification";

export const UPDATE_ALARM_STATE =
  "https://21737igctc.execute-api.us-west-2.amazonaws.com/prod/eDudeRIoTNotificationConfig";

export const UPDATE_MQTT_DATA =
  "https://hnz9bdk37g.execute-api.us-west-2.amazonaws.com/prod/eDudeRIoTMQTTPublish";
