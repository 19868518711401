import React, { useEffect, useMemo, useState } from "react";
import { useReactTable, getCoreRowModel } from "@tanstack/react-table";
import {
  GET_ALARM_STATE_DATA,
  alarmSatusDataColumn as originalColumnDefinition,
} from "../utils/constants";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Table from "./Table";
import ShimmerTable from "./ShimmerTable";
import DeviceNameSelect from "./DeviceNameSelect";
import UpdateAlarmState from "./UpdateAlarmState";

const AlarmState = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [data, setData] = useState([
    {
      "Device Name": "",
      "Text Alarm Message": "",
      "Text Message Number": "",
      "eDude HWID": "",
      "eMail Address": "",
      "eMail Alarm Message": "",
    },
  ]);
  const [alarmData, setAlarmData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(GET_ALARM_STATE_DATA, {
          params: {
            offset: 0,
            pageSize: 10,
            searchString: "",
          },
        });
        const data = response.data;

        setAlarmData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleOpenPopup = (row) => {
    setFormData(row); // Set the row data to formData
    setIsPopupOpen(true); // Open the popup window
  };

  const columnDefinition = originalColumnDefinition.map((col) => {
    if (col.accessorKey === "Device Name") {
      return {
        ...col,
        cell: ({ row }) => (
          <DeviceNameSelect
            rowIndex={row.index}
            alarmData={alarmData}
            setData={setData}
            initialValue={row.original["Device Name"]}
          />
        ),
      };
    }
    if (col.accessorKey === "Edit") {
      return {
        ...col,
        cell: ({ row, getValue }) => (
          <div className="flex items-center">
            {row.original["Device Name"] && (
              <>
                <span>{getValue()}</span>
                <button
                  className="ml-2 text-blue-500 hover:text-blue-700"
                  onClick={() => handleOpenPopup(row.original)}
                >
                  <FontAwesomeIcon
                    icon={faPen}
                    className="text-[#08605f] ml-3"
                  />
                </button>
              </>
            )}
          </div>
        ),
      };
    }
    return col;
  });

  const finalColumnDef = useMemo(() => {
    return [...columnDefinition];
  }, [columnDefinition]);

  const tableInstance = useReactTable({
    columns: finalColumnDef,
    data: data,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="container mx-auto p-4">
      <div className="text-start py-2 px-2">
        <h2 className="text-xl font-extrabold font-serif text-[#08605F] mb-4 drop-shadow-xl">
          Alarm State
        </h2>
      </div>

      <UpdateAlarmState
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        formData={formData}
        setFormData={setFormData}
        setData={setData}
      />

      <div className="z-10 bg-transparent border border-1 p-4 border-[#08605F] rounded-lg overflow-hidden">
        <div className=" mt-4">
          {data.length > 0 ? (
            <Table tableInstance={tableInstance} />
          ) : (
            <ShimmerTable />
          )}
        </div>
      </div>
      <hr />
    </div>
  );
};

export default AlarmState;
