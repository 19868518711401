import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faArrowLeft,
  faArrowRight,
  faTachometerAlt,
  faCalendarAlt,
  faChartBar,
  faQuestionCircle,
  faHome,
  faClockRotateLeft,
  faBars,
} from "@fortawesome/free-solid-svg-icons";

const Navbar = ({ isOpen, toggleNavbar, isMobile }) => {
  if (isMobile) {
    return (
      <div>
        <div className="flex justify-between items-center p-4 bg-[#08605F] text-white">
          <div className="text-2xl font-bold">RIoT</div>
          <button onClick={toggleNavbar} className="focus:outline-none">
            <FontAwesomeIcon icon={faBars} className="text-white text-xl" />
          </button>
        </div>
        {isOpen && (
          <div className="bg-[#08605F] text-white h-screen fixed top-0 left-0 w-full z-50">
            <div className="flex justify-end p-4">
              <button onClick={toggleNavbar} className="focus:outline-none">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-white text-xl"
                />
              </button>
            </div>
            <nav className="mt-10">
              <ul>
                <li
                  className="p-4 hover:bg-[#177E89] flex items-center"
                  data-tip="Home"
                >
                  <Link to="/" onClick={toggleNavbar}>
                    <FontAwesomeIcon icon={faHome} className="w-6 mr-2" />
                    <span>Home</span>
                  </Link>
                </li>

                <li className="p-4 hover:bg-[#177E89] flex items-center">
                  <Link to="/batteryState" onClick={toggleNavbar}>
                    <FontAwesomeIcon
                      icon={faTachometerAlt}
                      className="w-6 mr-2"
                    />
                    <span>Battery State</span>
                  </Link>
                </li>

                <li className="p-4 hover:bg-[#177E89] flex items-center">
                  <Link to="/alarmState" onClick={toggleNavbar}>
                    <FontAwesomeIcon
                      icon={faClockRotateLeft}
                      className="w-6 mr-2"
                    />
                    <span>Alarm State</span>
                  </Link>
                </li>

                <li className="p-4 hover:bg-[#177E89] flex items-center">
                  <Link to="/mqtt" onClick={toggleNavbar}>
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="w-6 mr-2"
                    />
                    <span>MQTT</span>
                  </Link>
                </li>

                <li className="p-4 hover:bg-[#177E89] flex items-center">
                  <FontAwesomeIcon icon={faChartBar} className="w-6 mr-2" />
                  <span>Analytics</span>
                </li>

                <li className="p-4 hover:bg-[#177E89] flex items-center">
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="w-6 mr-2"
                  />
                  <span>Help</span>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={`h-screen ${
        isOpen ? "w-48" : "w-16"
      } bg-[#08605F] text-white fixed transition-all duration-300 hidden sm:block`}
    >
      <div className="p-4 text-2xl font-bold text-center bg-[#08605F] font-serif relative">
        {isOpen && "RIoT"}
        <button
          onClick={toggleNavbar}
          className="absolute top-1/2 right-[-12px] transform -translate-y-1/2 focus:outline-none bg-white rounded-full px-2"
        >
          <FontAwesomeIcon
            icon={isOpen ? faArrowLeft : faArrowRight}
            className="text-black text-[16px]"
          />
        </button>
      </div>
      <nav className="mt-10">
        <ul>
          <li
            className="p-4 hover:bg-[#177E89] flex items-center"
            data-tip="Home"
          >
            <Link to="/">
              <FontAwesomeIcon icon={faHome} className="w-6 mr-2" />
              {isOpen && <span>Home</span>}
            </Link>
          </li>

          <li className="p-4 hover:bg-[#177E89] flex items-center">
            <Link to="/batteryState">
              <FontAwesomeIcon icon={faTachometerAlt} className="w-6 mr-2" />
              {isOpen && <span>Battery State</span>}
            </Link>
          </li>

          <li className="p-4 hover:bg-[#177E89] flex items-center">
            <Link to="/alarmState">
              <FontAwesomeIcon icon={faClockRotateLeft} className="w-6 mr-2" />
              {isOpen && <span>Alarm State</span>}{" "}
            </Link>
          </li>

          <li className="p-4 hover:bg-[#177E89] flex items-center">
            <Link to="/mqtt">
              <FontAwesomeIcon icon={faCalendarAlt} className="w-6 mr-2" />
              {isOpen && <span>MQTT</span>}
            </Link>
          </li>

          <li className="p-4 hover:bg-[#177E89] flex items-center">
            <FontAwesomeIcon icon={faChartBar} className="w-6 mr-2" />
            {isOpen && <span>Analytics</span>}
          </li>

          <li className="p-4 hover:bg-[#177E89] flex items-center">
            <FontAwesomeIcon icon={faQuestionCircle} className="w-6 mr-2" />
            {isOpen && <span>Help</span>}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
