/**
 * reportWebVitals Function
 *
 * This function is used to report various web vitals performance metrics.
 * It asynchronously imports web-vitals and calls its functions to measure
 * Cumulative Layout Shift (CLS), First Input Delay (FID), First Contentful Paint (FCP),
 * Largest Contentful Paint (LCP), and Time to First Byte (TTFB).
 *
 * @param {function} onPerfEntry - Callback function to receive performance entries.
 */

const reportWebVitals = (onPerfEntry) => {
  // Check if onPerfEntry is a function and then import web-vitals
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      // Call each web-vitals function with onPerfEntry callback
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
