import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { GET_ALARM_STATE_DATA, FETCH_DATA } from "../utils/constants";

const DeviceNameSelect = ({ rowIndex, alarmData, setData, initialValue }) => {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialValue) {
      setSelectedDevice({ label: initialValue, value: initialValue });
    }
  }, [initialValue]);

  const handleDeviceNameChange = async (newValue) => {
    setSelectedDevice(newValue);
    setLoading(true);
    console.log(alarmData);
    try {
      const device = alarmData.Data.find(
        (item) => item["Device Name"].trim() === newValue.label.trim()
      );
      if (device) {
        const requestData = {
          eDudeName: newValue.label,
          eDudeHWID: device["eDude HWID"],
        };

        const response = await axios.get(FETCH_DATA, {
          params: {
            eDudeName: requestData.eDudeName,
            eDudeHWID: requestData.eDudeHWID,
          },
        });

        if (response.status === 200 && response.data.Data) {
          const updatedRowData = response.data.Data;

          setData((prevData) => {
            const updatedData = [...prevData];
            updatedData[rowIndex] = {
              "Device Name": updatedRowData["Device Name"],
              "Text Alarm Message": updatedRowData["Text Alarm Message"],
              "Text Message Number": updatedRowData["Text Message Number"],
              "eDude HWID": updatedRowData["eDude HWID"],
              "eMail Address": updatedRowData["eMail Address"],
              "eMail Alarm Message": updatedRowData["eMail Alarm Message"],
            };
            return updatedData;
          });
        } else {
          console.error(
            "Failed to update device name. Status:",
            response.status
          );
        }
      } else {
        console.error("Device not found for name:", newValue.label);
      }
    } catch (error) {
      console.error("Error updating device name:", error);
    }
    setLoading(false);
  };

  const loadOptions = async (inputValue, callback) => {
    try {
      const response = await axios.get(GET_ALARM_STATE_DATA, {
        params: {
          searchString: inputValue,
          offset: 0,
          pageSize: 200,
        },
      });

      console.log("Data received:", JSON.stringify(response.data, null, 2));

      const options = response.data.Data.map((device) => ({
        label: device["Device Name"],
        value: device["Device Name"],
      }));

      callback(options);
    } catch (error) {
      console.error("Error fetching options:", error);
      callback([]);
    }
  };

  return (
    <div style={{ position: "relative", width: "200px" }}>
      <AsyncSelect
        cacheOptions
        defaultOptions
        loadOptions={loadOptions}
        value={selectedDevice}
        onChange={(newValue) => handleDeviceNameChange(newValue)}
        placeholder="Enter name"
        className="py-1 px-2 border rounded"
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
        }}
      />
      {loading && ( // Display loading spinner while updating
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-80 z-30">
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            size="4x"
            className="text-white mb-4"
          />

          <div className="text-white text-xl">Loading...</div>
        </div>
      )}
    </div>
  );
};

export default DeviceNameSelect;
