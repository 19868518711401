import React from "react";

const ShimmerTable = () => {
  return (
    <div className="shadow-md rounded-lg overflow-hidden">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <div className="bg-gray-200 h-4 w-12 rounded"></div>
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <div className="bg-gray-200 h-4 w-12 rounded"></div>
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <div className="bg-gray-200 h-4 w-12 rounded"></div>
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              <div className="bg-gray-200 h-4 w-12 rounded"></div>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {/* Shimmer rows */}
          {[...Array(5)].map((_, index) => (
            <tr key={index} className="animate-pulse">
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="bg-gray-200 h-4 w-12 rounded"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="bg-gray-200 h-4 w-24 rounded"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="bg-gray-200 h-4 w-32 rounded"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="bg-gray-200 h-4 w-20 rounded"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ShimmerTable;
