/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @file BatteryStateTable.js
 * @description Component for managing and displaying battery state data in a table format.
 *
 * This file defines a React component that fetches, processes, and displays battery state data using
 * the @tanstack/react-table library. It incorporates various features including sorting, filtering,
 * pagination, and row selection.
 *
 * Imports:
 * - React hooks: `useEffect`, `useMemo`, `useState` for managing component state and lifecycle.
 * - @tanstack/react-table functions: `useReactTable`, `getCoreRowModel`, `getSortedRowModel`, `getFilteredRowModel`
 *   for creating and managing the table instance.
 * - Constants: `CURRENT_STATE_FETCH_URL`, `GET_BATTTERY_STATE_DATA_URL`, and `columnDefinition` for API endpoints
 *   and column definitions.
 *
 * Components:
 * - `UpdateForm`: A form component for updating battery state data.
 * - `TablePagination`: A component for handling table pagination.
 * - `Table`: A component for rendering the table with the provided data and configurations.
 *
 * Icons:
 * - `FontAwesomeIcon` with `faPen` for edit actions.
 * - `FontAwesomeIcon` with `faGear` for settings actions.
 */
import React, { useEffect, useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import {
  CURRENT_STATE_FETCH_URL,
  GET_BATTTERY_STATE_DATA_URL,
  columnDefinition as originalColumnDefinition,
} from "../utils/constants";
import selectionColumn from "./SelectionColumn";
import axios from "axios";
import UpdateForm from "./UpdateForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import TablePagination from "./TablePagination";
import Table from "./Table";
import ShimmerTable from "./ShimmerTable";

const BatteryState = () => {
  /**
   * State Declarations and Memoization
   *
   * This section of the code defines state variables and a memoized value for managing
   * the application's data, form interactions, and table behaviors. The use of React hooks
   * such as useState and useMemo provides a clean and efficient way to handle state and
   * optimize performance.
   *
   */
  //const [isLoading, setIsLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [data, setData] = useState([]);
  const finalData = useMemo(() => data, [data]);
  const [sorting, setSorting] = useState([]);
  const [filter, setFilter] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [debouncedFilter, setDebouncedFilter] = useState("");
  /**
  * useEffect Hook for Debounced Filter
  * 
  * This useEffect hook implements a debouncing mechanism for the `filter` state.
  * Debouncing is used to limit the rate at which a function is executed, which is
  * particularly useful for improving performance when dealing with user input.
  
  * - The `debouncedFilter` state can be used in place of the `filter` state for operations
  *   that benefit from debouncing, such as API calls or filtering data in a table.
  */
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilter(filter);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [filter]);
  // Fetch initial data when component mounts: This inline comment clarifies the purpose of calling fetchData within the useEffect hook. It succinctly describes what the effect is intended to achieve.
  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize, debouncedFilter]);

  /**
   * Opens a popup window with data fetched from an API endpoint.
   *
   * Constructs a URL with query parameters based on the provided row's "eDude HWID"
   * and "eDude Name", then fetches data asynchronously from the API endpoint specified
   * by CURRENT_STATE_FETCH_URL. If successful, sets the fetched data into a state variable
   * formData and opens the popup window.
   *
   * @param {object} row - The row object containing "eDude HWID" and "eDude Name" properties.
   * @returns {Promise<void>} - A promise that resolves once data is fetched and popup is opened.
   */
  const handleOpenPopup = async (row) => {
    const apiEndpoint = CURRENT_STATE_FETCH_URL;
    const params = {
      eDudeHWID: row["eDude HWID"],
      eDudeName: row["eDude Name"],
    };

    const queryString = new URLSearchParams(params).toString();
    const urlWithParams = `${apiEndpoint}?${queryString}`;

    try {
      const response = await fetch(urlWithParams, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // Assuming setFormData and setIsPopupOpen are defined elsewhere in your component
      setFormData(data.Data); // Set the fetched data to formData
      setIsPopupOpen(true); // Open the popup window
    } catch (error) {
      console.error("Error making API call:", error.message);
    }
  };

  const columnDefinition = originalColumnDefinition.map((col) => {
    if (col.accessorKey === "Low Voltage Alert Setting") {
      return {
        ...col,
        cell: ({ row, getValue }) => (
          <div className="flex items-center">
            <span>{getValue()}</span>

            <button
              className="ml-2 text-blue-500 hover:text-blue-700"
              onClick={() => handleOpenPopup(row.original)}
            >
              <FontAwesomeIcon icon={faPen} className="text-[#08605f] ml-3" />
            </button>
          </div>
        ),
      };
    }
    return col;
  });

  /**
   * Memoized array of column definitions for a data table.
   *
   * Constructs a final array of column definitions by combining a selection column
   * and other column definitions. This array is memoized to optimize performance
   * by caching the result and recalculating it only when dependencies change.
   *
   * @type {array} - An array of column definitions, including a selection column followed by other columns.
   */
  const finalColumnDef = useMemo(() => {
    return [selectionColumn, ...columnDefinition];
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(GET_BATTTERY_STATE_DATA_URL, {
        params: {
          offset: pageIndex * pageSize,
          pageSize: pageSize,
          searchString: debouncedFilter,
        },
      });

      setData(response.data.Data);
      if (totalRows === 0) {
        setTotalRows(response.data.TotalRows);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  /**
   * Initializes a React table instance with specified configurations and data.
   *
   * This hook initializes a React table instance using the provided configurations,
   * including columns, data, row models for core, sorted, and filtered rows, manual
   * pagination settings, sorting, filtering, row selection, and pagination state.
   * It also manages state updates for sorting, filtering, row selection, and pagination
   * changes through respective callback functions.
   *
   * @type {object} - The initialized React table instance.
   */
  const tableInstance = useReactTable({
    columns: finalColumnDef,
    data: finalData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(totalRows / pageSize),
    state: {
      sorting: sorting,
      globalFilter: filter,
      rowSelection: rowSelection,
      pagination: {
        pageIndex: pageIndex,
        pageSize: pageSize,
      },
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFilter,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: ({ pageIndex, pageSize }) => {
      setPageIndex(pageIndex);
      setPageSize(pageSize);
    },
    // Enable row selection feature
    enableRowSelection: true,
  });

  return (
    <div className="container mx-auto p-4 ">
      <div className="text-start py-2 px-2">
        <h2 className="text-xl font-extrabold font-serif text-[#08605F] mb-4 drop-shadow-xl">
          Battery State
        </h2>
      </div>

      {/* Renders the UpdateForm component for editing data, controlled by isOpen state, with onClose callback to close the form, formData for current form data, setFormData to update form data, and setData to update parent component data.*/}
      <UpdateForm
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        formData={formData}
        setFormData={setFormData}
        setData={setData}
      />

      <div className="z-10 bg-transparent border border-1 p-4 border-[#08605F] rounded-lg overflow-hidden">
        <div
          className="flex flex-col md:flex-row justify-between items-start
         space-y-4 md:space-y-0 md:space-x-4"
        >
          <div className="pt-2  text-gray-600">
            {/** Input field for searching the table values */}
            <input
              className="border-[1px] border-[#08605F] bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
              type="search"
              name="search"
              placeholder="Search"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
        </div>

        <div className="overflow-x-auto mt-4">
          {data.length > 0 ? (
            <Table tableInstance={tableInstance} />
          ) : (
            <ShimmerTable /> // Render shimmer effect while loading
          )}
        </div>
      </div>
      <hr />

      {/* Renders pagination controls for tableInstance data, allowing users to navigate through pages, set page size, and displaying current page index and total rows.*/}
      <TablePagination
        tableInstance={tableInstance}
        setPageIndex={setPageIndex}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        totalRows={totalRows}
        pageSize={pageSize}
      />
    </div>
  );
};

// Export the BatteryState component as the default export.
export default BatteryState;
