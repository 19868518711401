/********************************************************************************************
 *   COPYRIGHT (C) 2024 RIoT
 *   The reproduction, transmission or use of this document/file or its
 *   contents is not permitted without  written authorization.
 *   Offenders will be liable for damages. All rights reserved.
 *---------------------------------------------------------------------------
 *   Purpose:  Table.js
 *   Project:  RIoT
 *   IDE:	  VS Code
 ********************************************************************************************/
import React from "react";
import { flexRender } from "@tanstack/react-table";
/**
 * Table component for rendering a dynamic table using react-table library.
 *
 * This component displays table headers and rows based on the provided tableInstance,
 * utilizing flexRender for flexible rendering of header and cell content.
 *
 * @param {Object} tableInstance - Instance of the react-table for managing table state and rendering.
 * @returns {JSX.Element} - Rendered JSX for the Table component.
 */
const Table = ({ tableInstance }) => {
  return (
    <table className="w-full sm:w-[100%] max-h-[300px] overflow-y-auto block">
      <thead>
        {tableInstance.getHeaderGroups().map((headerEl) => (
          <tr key={headerEl.id}>
            {headerEl.headers.map((columnEl) => (
              <th
                className="py-2 px-4 border-b border-gray-200 bg-[#ffffff] text-left text-sm font-bold text-[#08605F]"
                key={columnEl.id}
                colSpan={columnEl.colSpan}
                onClick={columnEl.column.getToggleSortingHandler()}
              >
                {flexRender(
                  columnEl.column.columnDef.header,
                  columnEl.getContext()
                )}
                {
                  {
                    asc: " 🔼",
                    desc: " 🔽",
                  }[columnEl.column.getIsSorted() ?? null]
                }
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {tableInstance
          .getRowModel()
          .rows.slice(0, 10)
          .map((rowEl) => (
            <tr key={rowEl.id} className="text-[#08605F] text-sm">
              {rowEl.getVisibleCells().map((cellEl, index) => (
                <React.Fragment key={cellEl.id}>
                  <td className="py-2 px-4 border-b border-gray-200">
                    {flexRender(
                      cellEl.column.columnDef.cell,
                      cellEl.getContext()
                    )}
                  </td>
                  {(index + 1) % 2 === 0 && <></>}
                </React.Fragment>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default Table;
