/********************************************************************************************
 *   COPYRIGHT (C) 2024 RIoT
 *   The reproduction, transmission or use of this document/file or its
 *   contents is not permitted without  written authorization.
 *   Offenders will be liable for damages. All rights reserved.
 *---------------------------------------------------------------------------
 *   Purpose:  Footer.js
 *   Project:  RIoT
 *   IDE:      VS Code
 ********************************************************************************************/
import React from "react";

/**
 * Footer component for displaying contact information and copyright notice.
 *
 * This component renders a footer section typically placed at the bottom of the page.
 * It includes contact information, a link to customer support, and a copyright notice.
 *
 * @param {boolean} isSidebarOpen - State to determine if the sidebar is open.
 * @param {string} footerPosition - State to determine the footer position (fixed or relative).
 * @returns {JSX.Element} - Rendered JSX footer component.
 */
const Footer = ({ isSidebarOpen, footerPosition }) => {
  return (
    <footer
      className={`w-full bg-white text-black py-4 text-sm transition-all duration-300 ${
        footerPosition === "fixed" ? "fixed bottom-0" : "relative"
      } ${isSidebarOpen ? "ml-48" : "ml-16"}`}
    >
      <div className="text-start ml-2">
        <div className="flex text-xs mb-1">
          {/* Renders a "Contact" and "Customer Support" label with medium font weight and custom teal color. */}
          <p className="text-[#08605F] font-medium pr-2">Contact</p>
          <p className="text-[#08605F] font-medium">Customer Support</p>
        </div>

        {/* Renders the copyright notice for the year 2024 with the company name "RIoT" and asserts all rights reserved. */}
        <p className="text-xs font-sans">@2024 RIoT. All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
